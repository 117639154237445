@use "src/styles/breakpoints";
@use "src/styles/theme";

$width: calc(11 * var(--spacing-mega));

.leftSection {
  display: flex;
  align-items: center;

  > svg {
    fill: var(--color-text-faded);
    opacity: var(--opacity-50);
    width: calc(1.4 * var(--spacing-medium));
  }

  .placeholder {
    margin-inline-start: var(--spacing-small);
  }
}

.container {
  position: relative;
  transition: box-shadow var(--transition-fast) ease,
    top var(--transition-fast) ease;
  box-sizing: border-box;

  background: var(--color-background-elevated);

  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 calc(1.5 * var(--spacing-medium));
  color: var(--color-text-faded);
  min-height: calc(3 * var(--spacing-large));
  @include breakpoints.tablet {
    min-height: calc(3.5 * var(--spacing-large));
  }
  border-radius: var(--border-radius-pill);
  cursor: pointer;
  outline: inherit;
  font-size: var(--font-size-large);
  inset-block-start: 0;
  @include theme.light {
    box-shadow: var(--shadow-small);
    &:hover {
      color: var(--color-text-default);
      box-shadow: var(--shadow-large);
    }
  }
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchButtonWrapper {
  margin-inline-end: calc(-1 * var(--spacing-xsmall));
  margin-inline-start: calc(var(--spacing-xsmall));
}
